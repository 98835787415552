import React from "react";
import { Icon } from '@iconify/react';
import "./Footer.css";

function Footer() {


  return (
    <div className='footer'>

      <div className="footer-layer">
      <div className='container'>




        <div className='row'>
          <div className='col-6'>
            <div className='my-2'>
              <a href="/home">
              <img
                className='mt-2 my-footer-image'
                src={"images/Logo1.webp"}
                alt='logo'
              />
              </a>
            </div>
          </div>

          <div className='col-6  d-md-inline text-end'>
            <div className='mt-4 mt-md-5 my-fs me-4'>
            <p className='d-inline ms-1'> تابعـــونا على </p>
            <span className='ms-2'>
              <a href='https://www.facebook.com/ServicesCompany/' target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-facebook-f my-soial-icon"></i>
              </a>
            </span>
            <span className='ms-2'>
              <a href='https://twitter.com/psctly?s=11&t=UUt70eUd6wnrFtUkz0BUew' target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-twitter my-soial-icon"></i>
              </a>
            </span>
            </div>
          </div>
        </div>



        <div className="row my-2 my-fs-2 row-cols-2 row-cols-sm-3">

          <div className="col-xs-6 col-sm-4 col-md-4">
            <ul className="nav flex-column">
              <li className="nav-item"><a href="/home" className="nav-link nav-footer-item"> <Icon className="footer-icon" icon="akar-icons:link-chain"  /> الرئيسية</a></li>
              <li className="nav-item"><a href="/news" className="nav-link nav-footer-item"> <Icon className="footer-icon" icon="akar-icons:link-chain" /> أخبار شركة الخدمـــات العامة</a></li>
              <li className="nav-item"><a href="/decisions" className="nav-link nav-footer-item"> <Icon className="footer-icon" icon="akar-icons:link-chain"  /> قرارات شركة الخدمـــات العامة</a></li>
            </ul>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-4">
            <ul className="nav flex-column">
              <li className="nav-item"><a href="/departments" className="nav-link nav-footer-item"> <Icon className="footer-icon" icon="akar-icons:link-chain"  /> إدارات شركة الخدمـــات العامة</a></li>
              <li className="nav-item"><a href="/issue-report" className="nav-link nav-footer-item"> <Icon className="footer-icon" icon="akar-icons:link-chain"  /> تقديم بلاغ إلكتروني</a></li>
              <li className="nav-item"><a href="/map" className="nav-link nav-footer-item"> <Icon className="footer-icon" icon="akar-icons:link-chain"  /> خريطة حاويات القمامة</a></li>
            </ul>
          </div>
          <div className="col-xs-6 col-sm-4 col-md-4">
            <ul className="nav flex-column">
              <li className="nav-item nav-link nav-footer-item"> <Icon className="footer-icon" icon="ci:mail"/> info@psct.gov.ly</li>
              <li className="nav-item nav-link nav-footer-item"> <Icon className="footer-icon"  icon="ant-design:phone-outlined"/> 094-412-22-22</li>
            </ul>
          </div>


        </div>





        <div className="row my-5 justify-content-center my-fs-2">

        <div className="col-3"><hr/></div>
        <div className="col-3 text-center">جميع الحقوق محفوظة          © 2022 </div>
        <div className="col-3"><hr/></div>

        </div>











      </div>
      </div>

    </div>
  );
}

export default Footer;
