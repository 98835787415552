import React from "react";
import "./Decisions.css";
// import { Icon } from "@iconify/react";
import DecesionsBoard from "../Home/DecesionsBoard";



function Decisions() {
  return (
    <>
      <div className='row justify-content-between mt-5 container'>
        <div className='col'>
          <p className="h3"> قوانين وقرارات شركة الخدمات العامة</p>
        </div>
      </div>
      <DecesionsBoard style={{marginTop:'3rem',marginBottom:'5rem'}} />
    </>
  );
}

export default Decisions;


 