import React from 'react'
import { addsBarData } from '../../data/addsBarData'
// import { Icon } from '@iconify/react';


function AdsBar() {
  return (
    <div className='container my-5'>
  <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" aria-label="Slide 12"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="12" aria-label="Slide 13"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="13" aria-label="Slide 14"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="14" aria-label="Slide 15"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="15" aria-label="Slide 16"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="16" aria-label="Slide 17"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="17" aria-label="Slide 18"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="18" aria-label="Slide 19"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="19" aria-label="Slide 20"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="20" aria-label="Slide 21"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="21" aria-label="Slide 22"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="22" aria-label="Slide 23"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="23" aria-label="Slide 24"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="24" aria-label="Slide 25"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="25" aria-label="Slide 26"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="26" aria-label="Slide 27"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="27" aria-label="Slide 28"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="28" aria-label="Slide 29"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="29" aria-label="Slide 30"></button>

  </div>
  <div className="carousel-inner">
  <div className="carousel-item active">
    <img className='w-100 h-100 rounded-3 d-block' src={addsBarData[0].src} alt={addsBarData[0].alt}/>
    </div>
    <div className="carousel-item">
    <img className='w-100 h-100  rounded-3 d-block ' src={addsBarData[1].src} alt={addsBarData[1].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[2].src} alt={addsBarData[2].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[3].src} alt={addsBarData[3].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[4].src} alt={addsBarData[4].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[5].src} alt={addsBarData[5].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[6].src} alt={addsBarData[6].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[7].src} alt={addsBarData[7].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[8].src} alt={addsBarData[8].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[9].src} alt={addsBarData[9].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[10].src} alt={addsBarData[10].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[11].src} alt={addsBarData[11].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[12].src} alt={addsBarData[12].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[13].src} alt={addsBarData[13].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[14].src} alt={addsBarData[14].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[15].src} alt={addsBarData[15].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[16].src} alt={addsBarData[16].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[17].src} alt={addsBarData[17].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[18].src} alt={addsBarData[18].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[19].src} alt={addsBarData[19].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[20].src} alt={addsBarData[20].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[21].src} alt={addsBarData[21].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[22].src} alt={addsBarData[22].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[23].src} alt={addsBarData[23].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[24].src} alt={addsBarData[24].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[25].src} alt={addsBarData[25].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[26].src} alt={addsBarData[26].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[27].src} alt={addsBarData[27].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[28].src} alt={addsBarData[28].alt}/>
    </div>
    <div className="carousel-item">
    <img className='h-100 w-100 rounded-3 d-block ' src={addsBarData[29].src} alt={addsBarData[29].alt}/>
    </div>



  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

</div>

   
  )
}

export default AdsBar