import React from 'react'
import InfoWindow from './InfoWindow'

function Marker({ show, place }) {
    const markerStyle = {
        // border: '1px solid white',
        borderRadius: '50%',
        height: 20,
        width: 20,
        
        // backgroundColor: show ? 'red' : 'blue',
        // backgroundImage: `url("../../../public/images/delete.png")`,
        cursor: 'pointer',
        zIndex: 10,
      };
    
      return (
        <>
          <div style={markerStyle} >
          <img src="images/icons8-trash-flat-32.png" alt='marker'/>
          </div>
          {show && <InfoWindow place={place} />}
        </>
      );
}

export default Marker