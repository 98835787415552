import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";



function View({ data }) {
  const navigate = useNavigate();

  return (
    <>
      <img
        className='d-block w-100'
        src={`images/${data.image}`}
        alt='departments'
      />

      <button
        className='btn btn-block w-75'
        onClick={() => {
          navigate("/department", { state: { data: data } });
        }}
      >
        {data.buttonText}
        <Icon
          className='ms-2'
          icon='ic:baseline-auto-awesome-motion'
          width='30'
          height='30'
        />
      </button>
    </>
  );
}

export default View;
