import React,{useState} from "react";
import { useLocation } from "react-router-dom";
import SeeMore from "../../components/SeeMore/SeeMore";



function Department() {
  const [display,setDisplay]=useState('none')
  const [division,setDivision] = useState('')
  const [divisionManager,setDivisionManager] = useState('')


  const location = useLocation();


  return (
    <div className='container'>
      <div className='container mt-5'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item fw-bold'>
              <a href='/home'>الرئيسة</a>
            </li>
            <li className='breadcrumb-item fw-bold ' aria-current='page'>
              <a href='/departments'>الإدارات</a>
            </li>
            <li className='breadcrumb-item fw-bold text-muted' aria-current='page'>
              صفحة الإدارة
            </li>
          </ol>
        </nav>
      </div>


      <div className='row'>
        <div className='col text-center'>
          <p className='my-text-primary display-6 fw-bold mt-5'>
            {location.state.data.name} 
          </p>
        </div>
      </div>

      <div className='row my-4 align-items-center'>
        <div className=' col-xs-1 mb-3 col-sm-3 col-md-2 col-lg-2 col-xl-2'>
          <img
            className='avatar'
            src={location.state.data.departmentDetails.managerPhoto===''?`images/managers/Portrait_Placeholder.webp`:`images/${location.state.data.departmentDetails.managerPhoto}`}
            alt={location.state.data.departmentDetails.managerName}
          />
        </div>
        <div className=' col-xs-12  col-sm-9 col-md-10 col-lg-10 col-xl-10 '>
        <p className='h4'> {location.state.data.departmentDetails.managerName} </p>
          <p className='text-muted'>مدير {location.state.data.name}</p>
        </div>
      </div>

      <div className='border-top border-1 border-dark'></div>{" "}
      {/* break line  */}
      <div className='row justiy-content-center mt-5'>
        <div className='col text-center'>
          <img
            className='department-image rounded-3'
            src={location.state.data.departmentDetails.mainPhoto===""?`images/departments/placeholder.webp`:`images/${location.state.data.departmentDetails.mainPhoto}`}
            alt='main'
          />
        </div>
      </div>

      <div className='row container mb-5'>
        {location.state.data.departmentDetails.divisions.length > 0?
        <>
          <p className="my-text-primary h3">تنقسم {location.state.data.name} إلي</p>
          <div className='col mt-3'>
            <div className='scroll'>
              {location.state.data.departmentDetails.divisions.map((item,i)=>{
                return(<div className='scroll-item ms-1 ' key={i}><button className={division === item.divisionName ? `my-button p-2 my-button-active ` : `my-button p-2`} onClick={(e)=>{
                  e.preventDefault()
                  setDisplay('block')
                  setDivision(item.divisionName)
                  setDivisionManager(item.divisionManager)
                }}>{item.divisionName}</button></div>)
              })}
              
            </div>
          </div>
        </>
        :''}
      </div>

      <div className="row container my-5" style={{display:`${display}`}}>
        <div className="col">
        <p className='fw-bold'>مدير {division} : {divisionManager} </p>
        </div>

      </div>


      <SeeMore />
    </div>
  );
}

export default Department;
