import React from "react";
import { Icon } from "@iconify/react";



function DecesionsBoard({ style }) {
  return (
    <div style={style}>

      <div className='container mt-4'>
        <div className='row justify-content-around'>
          <div className='col-sm-5 col-md-5 '>
            <div className='card text-center decision-1'>
              <div className='card-body'>
                <h4 className='card-title text-start'>13#</h4>
                <h4 className='card-title text-start my-text-primary'>
                  الأحكام الخاصة بالنظافة العامة
                </h4>

                <p className='card-title text-muted text-start mt-5 '>
                  {" "}
                  <span>
                    {" "}
                    <Icon
                      icon='ant-design:file-outlined'
                      width='15'
                      height='15'
                    />{" "}
                    الإدارة العامة
                  </span>{" "}
                  <span>
                    <Icon icon='iwwa:year' width='15' height='15' /> 1984م
                  </span>
                </p>
                <a
                  className='nav-link my-text-primary'
                  href='files/Law13.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <p className='card-title font-weight-bold text-end me-5'>
                    {" "}
                    <strong className='me-2'>عرض القرار</strong>
                    <Icon icon='akar-icons:arrow-left' width='15' height='15' />
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-md-5 mt-2 '>
            <div className='card text-center decision-1'>
              <div className='card-body'>
                <h4 className='card-title text-start'>23#</h4>
                <h4 className='card-title text-start my-text-primary'>
                  النشاط التجاري
                </h4>

                <p className='card-title text-muted text-start mt-5 '>
                  {" "}
                  <span>
                    {" "}
                    <Icon
                      icon='ant-design:file-outlined'
                      width='15'
                      height='15'
                    />{" "}
                    الإدارة العامة
                  </span>{" "}
                  <span>
                    <Icon icon='iwwa:year' width='15' height='15' /> 2010م
                  </span>
                </p>
                <a
                  className='nav-link my-text-primary'
                  href='files/Law23.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <p className='card-title font-weight-bold text-end me-5'>
                    {" "}
                    <strong className='me-2'>عرض القرار</strong>
                    <Icon icon='akar-icons:arrow-left' width='15' height='15' />
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-around'>
          <div className='col-sm-5 col-md-5 mt-2 '>
            <div className='card text-center decision-1'>
              <div className='card-body'>
                <h4 className='card-title text-start'>59#</h4>
                <h4 className='card-title text-start my-text-primary'>
                  نظام الإدارة المحلية
                </h4>
                <p className='card-title text-muted text-start mt-5 '>
                  {" "}
                  <span>
                    {" "}
                    <Icon
                      icon='ant-design:file-outlined'
                      width='15'
                      height='15'
                    />{" "}
                    الإدارة العامة
                  </span>{" "}
                  <span>
                    <Icon icon='iwwa:year' width='15' height='15' /> 2012م
                  </span>
                </p>

                <a
                  className='nav-link my-text-primary'
                  href='files/Law59.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <p className='card-title font-weight-bold text-end me-5'>
                    {" "}
                    <strong className='me-2'>عرض القرار</strong>
                    <Icon icon='akar-icons:arrow-left' width='15' height='15' />
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-md-5 mt-2 '>
            <div className='card text-center decision-1'>
              <div className='card-body'>
                <h4 className='card-title text-start'>15#</h4>
                <h4 className='card-title text-start my-text-primary'>
                  قانون حماية وتحسين البيئة
                </h4>

                <p className='card-title text-muted text-start mt-5 '>
                  {" "}
                  <span>
                    {" "}
                    <Icon
                      icon='ant-design:file-outlined'
                      width='15'
                      height='15'
                    />{" "}
                    الإدارة العامة
                  </span>{" "}
                  <span>
                    <Icon icon='iwwa:year' width='15' height='15' /> 2003
                  </span>
                </p>

                <a
                  className='nav-link my-text-primary'
                  href='files/Law15.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <p className='card-title font-weight-bold text-end me-5'>
                    {" "}
                    <strong className='me-2'>عرض القرار</strong>
                    <Icon icon='akar-icons:arrow-left' width='15' height='15' />
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DecesionsBoard;
