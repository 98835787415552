import React from 'react'

function InfoWindow({ place }) {

    const infoWindowStyle = {
      position: 'relative',
      bottom: 40,
      left: '-25px',
      width: 220,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      fontSize: 14,
      zIndex: 100,
    };
  
    return (
      <div style={infoWindowStyle}>
        <div className='text-center' style={{ fontSize: 16 }}>
            <p className='h5 fw-bold'>{place.areaName}</p>
            <p className='text-end fw-bold my-text-primary'>{place.lng}, {place.lat}</p>
        </div>
      </div>
    );
}

export default InfoWindow