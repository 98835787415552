import React from 'react';

import Router from "./routes";
import './components/Header/Header.css'




function App() {
  return (
    <>
        <Router/>
    </>
  );
}

export default App;
