import React, { useState,useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import "./Map.css";
import SeeMore from "../../components/SeeMore/SeeMore";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Marker from "./Marker";
import axios from "axios";
import Swal from 'sweetalert2'


const config = {
  headers: { "content-type": "multipart/form-data" },
};

function MapScreen() {

  const schema = yup.object().shape({
    place: yup
      .string()
      .trim()
      .matches(/^[\u0621-\u064A0-9 -_:.,،]+$/, "يجب أن يحتوي على حروف فقط")
      .required("يجب ادخال المكان"),
  });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [myplace, setMyplace] = useState([]);
  const [defaultProps,setDefaultProps] = useState({
    center: {
      lat: 32.885353,
      lng: 13.180161,
    },
    zoom: 12,
  })

  useEffect(() => {
    const getContainers = async ()=>{
      const {data} = await axios.get('https://ps-node-prod.psct.gov.ly/containers')
      setMyplace(data.containers)
    }
    getContainers()

  }, []);



  const onSubmit = async (data) => {

    await axios
    .post(`https://ps-node-prod.psct.gov.ly/containers/find`, {keyword:data.place}, config)
    .then((res) => {
      reset({
        place: "",
      });

      console.log(res)
      console.log(myplace)

      setMyplace([...myplace].map(item=>{
        if(item.containerId === res.data.Container.containerId){
          return {
            ...item,
            info:true
          }
        }else{
          
          return {...item,info:false}
        }
      }));

    })
    .catch((e) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'فشلت العملية، الرجاء المحاولة مرة أخرى',
      })
    });
  };


  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key) => {
    setMyplace([...myplace].map(item=>{
      if(item.containerId === key){
        return {
          ...item,
          info:!item.info
        }
      }else{
        
        return {...item,info:false}
      }
    }));
  };

  return (
    <div className='container'>
      <div className='card m-auto container  map-container '>
        <div className='row'>
          <div className='col'>
            <div className='card-title text-start mt-3 ms-2'>
              <h4>قم بالبحث عن أقرب مكب إليك</h4>
            </div>
            <div className='card-subtitle text-muted text-start mt-2 ms-2'>
              <small>يمكنك البحث عن مكب باستخدام منطقة السكن</small>
              <br />
              <small className=''>مثل (تاجوراء, حي الأندلس, المنصورة)</small>
            </div>
            <div className='card-body'>
              <form className='form-group' onSubmit={handleSubmit(onSubmit)}>
                <input
                  type='text'
                  className='form-control w-75 m-auto'
                  placeholder='ابحث عن...'
                  {...register("place")}
                />
                {errors.place && (
                  <p className='text-danger fw-bold'>{errors.place.message}</p>
                )}
                <button className='btn btn-block' type='submit'>
                  بحث <Icon icon='bx:search-alt-2' width='20' height='20' />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Google map */}
      <div className=' my-map container my-5'>
        <div className='d-flex justify-content-center'>
          <div className='w-75'>
            <p className='h4'>شركة الخدمات العامة - خريطة حاويات القمامة</p>
            <div className='border-top border-1 border-dark'></div>
          </div>
        </div>
        <div
          className='mt-5'
          style={{
            height: "85vh",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDUkZXBHfBfNpvD8IS59wWDcsYfZqm3-GI",
            }}
          center={defaultProps.center}
            zoom={defaultProps.zoom}
            onChildClick={onChildClickCallback}
            
          >
            {myplace && myplace.map((place)=>{
              console.log(place.areaName)
                return(
                  <Marker
                  key={place.containerId}
                  lat={place.lat}
                  lng={place.lng}
                  show={place.info}
                  place={place}
                />
                )
              })
            }


          </GoogleMapReact>
        </div>
      </div>

      <div className=' row mb-5'>
        <div className='row justify-content-between mb-3'>
          <div className='col-12 ms-3'>
            <h5 className='my-text-primary'> نتائج البحث</h5>
          </div>
          <div className='col-2 '></div>
        </div>

        <div className='row justify-content-around'>
          <div className='col-md-4 col-xs-12 col-s-12 ms-5'>
            <p className='fw-bold'>المنصورة طرابلس</p>
            <p className='d-flex justify-content-start'>
              <span className='text-muted'>الإحداثيات: </span>{" "}
              <span className='my-text-primary ms-2'>
                12.123421324,32.123412341
              </span>
            </p>
          </div>
          <div className='col-md-4 col-xs-12 col-s-12 ms-5'>
            <p className='fw-bold'>المنصورة طرابلس</p>
            <p className='d-flex justify-content-start'>
              <span className='text-muted'>الإحداثيات: </span>{" "}
              <span className='my-text-primary ms-2'>
                12.123421324,32.123412341
              </span>
            </p>
          </div>
        </div>
      </div>

      <SeeMore />
    </div>
  );
}

export default MapScreen;
// [
//   {
//   id: 1,
//   show: false,
//   name: "my place1",
//   geometry: {
//     location: {
//       lat: 32.885353,
//       lng: 13.180161,
//     },
//   },
// },
//   {
//   id: 2,
//   show: false,
//   name: "my place2",
//   geometry: {
//     location: {
//       lat: 32.885353,
//       lng: 13.160161,
//     },
//   },
// },

// ]