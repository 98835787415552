export const departments = [
    {
      id: "0",
      name: "إدارة الإنتاج",
      image: "departments/إدارة الإنتاج.webp",
      buttonText: "تعرف علي إدارة الإنتاج من هنا",
      state: true,
      departmentDetails: {
        title: "إدارة الإنتاج",
        managerPhoto: "",
        managerName: "محمود محمد علي الكلباش",
        mainPhoto: "departments/الانتاج.webp",
        divisions: [
          { divisionName: "قسم الحدادة واللحام", divisionManager: "عبدالمنعم محمد الذيب" },
          { divisionName: "قسم الخراطة", divisionManager: "محسن علي الزياني" },
          { divisionName: "قسم النجارة", divisionManager: "معمر محمد يونس ابودين" },
          { divisionName: "قسم الشوارع وصناعة المعدات", divisionManager: "عبدالمولى خليفة محمد" },
          { divisionName: "قسم خياطة الرايات", divisionManager: "اسامة محمد فرعون" },
        ],
      },
    },
  
    {
      id: "1",
      name: "إدارة الورش",
      image: "departments/إدارة الورش.webp",
      buttonText: "تعرف علي إدارة الورش من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الورش",
        managerPhoto: "",
        managerName: "فتحي محمد البير",
        mainPhoto: "departments/الورش.webp",
        divisions: [
          { divisionName: "قسم صيانة الآليات الخفيفة", divisionManager: "سعيد أحمد ابودراع" },
          { divisionName: "قسم صيانة الآليات الثقيلة", divisionManager: "فوزي قريرة علي" },
          { divisionName: "قسم السمكرة ةالطلاء", divisionManager: "محمد محمد المصري" },
          { divisionName: "قسم الصيانة الكهربائية للآليات", divisionManager: "محمد رمضان الماجري" },
          { divisionName: "قسم الصيانة الدورية", divisionManager: "محمد عمران عوض" },
          { divisionName: "قسم الغسيل والتشحيم", divisionManager: "رمضان محمد الشوق" },
        ],
      },
    },
    {
      id: "2",
      name: "إدارة الحدائق",
      image: "departments/إدارة الحدائق.webp",
      buttonText: "تعرف علي إدارة الحدائق من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الحدائق",
        managerPhoto: "",
        managerName: "محمد عبدالرحمن عبيه",
        mainPhoto: "departments/الحدائق.webp",
        divisions: [
          { divisionName: "قسم التقليم", divisionManager: "أحمد حسين السائح" },
          { divisionName: "قسم الحصادات", divisionManager: "عبدالباسط محمد الاقاري" },
          { divisionName: "قسم الرعاية", divisionManager: "شكري علي ابوجناح" },
          { divisionName: "قسم المشاتل", divisionManager: "عزالدين محمد الذيب" },
          { divisionName: "قسم الفنى", divisionManager: "فيضي علي الخبولي" },
        ],
      },
    },
    {
      id: "3",
      name: "إدارة المشروعات",
      image: "departments/إدراة المشروعات.webp",
      buttonText: "تعرف علي إدارة المشروعات من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة المشروعات",
        managerPhoto: "",
        managerName: "يوسف سالم سليمان",
        mainPhoto: "departments/المشروعات.webp",
        divisions: [
          { divisionName: "قسم المقايسات", divisionManager: "ناصر بلعيد قدقور" },
          { divisionName: "قسم الدراسات والتصميم", divisionManager: "ناصر ابراهيم الشتيوي" },
          { divisionName: "قسم الإشراف والمتابعة", divisionManager: "مراد محمود محمد الشتيوي" },
          { divisionName: " قسم الأعمال المدنية والسباكه", divisionManager: "عطية محمد البطل" },
        ],
      },
    },
    {
      id: "4",
      name: "إدارة الوقاية الصحية",
      image: "departments/إدارة الوقاية الصحية.webp",
      buttonText: "تعرف علي إدارة الوقاية الصحية من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الوقاية الصحية",
        managerPhoto: "",
        managerName: "وليد صالح شعيب",
        mainPhoto: "departments/الوقاية.webp",
        divisions: [
          { divisionName: "قسم الرش", divisionManager: "طارق محمد ابوقصة" },
          { divisionName: "قسم مكافحة القوارض", divisionManager: "خليفة الصيد مادي" },
          { divisionName: "قسم الحيوانات الشاردة", divisionManager: "هيثم صالح الشوشان" },
          { divisionName: "قسم التعقيم", divisionManager: "خالد علي الشبلي" },
          { divisionName: "قسم الصيانة والتجهيزات", divisionManager: "عثمان بلقاسم شيرة" },
        ],
      },
    },
    {
      id: "5",
      name: "إدارة الإحتفالات والزينة",
      image: "departments/إدارة الإحتفالات والزينة.webp",
      buttonText: "تعرف علي إدارة الإحتفالات والزينة من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الإحتفالات والزينة",
        managerPhoto: "",
        managerName: "أسامة شعبان سعيد",
        mainPhoto: "departments/الاحتفالات.webp",
        divisions: [
          { divisionName: "قسم النافورات", divisionManager: "صالح خليفة المزبون" },
          { divisionName: "قسم الزينة", divisionManager: "السائح علي المرضي" },
          { divisionName: "قسم الشؤون الفنية", divisionManager: "نوري محمد الجهاني" },
          { divisionName: "قسم صيانة التجهيزات", divisionManager: "محمد بلقاسم عبدالله" },
          { divisionName: "قسم المنصات", divisionManager: "محمد الصديق البلعزي" },
        ],
      },
    },
    {
      id: "6",
      name: "إدارة تقنية المعلومات",
      image: "departments/إدارة-تقنية-المعلومات.webp",
      buttonText: "تعرف علي إدارة تقنية المعلومات من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة تقنية المعلومات",
        managerPhoto: "",
        managerName: "",
        mainPhoto: "",
        divisions: [
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
        ],
      },
    },
    {
      id: "7",
      name: "إدارة التخطيط والمتابعة",
      image: "departments/إدارة-التخـطيط-والمتابعة.webp",
      buttonText: "تعرف علي إدارة التخطيط والمتابعة من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة التخطيط والمتابعة",
        managerPhoto: "",
        managerName: "عبدالحميد يوسف الساقزلي",
        mainPhoto: "departments/إدارة-التخـطيط-والمتابعة.webp",
        divisions: [
          { divisionName: "قسم التخطيط", divisionManager: "خالد سالم التائب" },
          { divisionName: "قسم البيانات", divisionManager: "عبدالنبئ علي الشقمان" },
          { divisionName: "قسم الإحصائيات", divisionManager: "محمد بلقاسم المحجوب" },
          { divisionName: "قسم التوثيق والمعلومات", divisionManager: "عبدالسميع يوسف الشويرف" },

        ],
      },
    },
    {
      id: "8",
      name: "إدارة الحركة والنقل",
      image: "departments/إدارة-الحركة-والنقل.webp",
      buttonText: "تعرف علي إدارة الحركة والنقل من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الحركة والنقل",
        managerPhoto: "",
        managerName: "حافظ محمد مرسيط",
        mainPhoto: "departments/إدارة-الحركة-والنقل.webp",
        divisions: [
          { divisionName: "قسم الحركة", divisionManager: "منصف علي بركه" },
          { divisionName: "قسم متابعة الآليات", divisionManager: "فتحي ميلود بن عيسى" },
          { divisionName: "قسم الوقود", divisionManager: "المرغني محمد العزيبي" },
          { divisionName: "قسم الغسيل والتشحيم", divisionManager: "محمد خليفة سلامة" },
          { divisionName: "قسم الصيانة الطارئة", divisionManager: "كمال الشتيوي الحكيمي" },
          { divisionName: "قسم المكبات المتنقلة", divisionManager: "فرج عبدالمولى علي" },
          { divisionName: "قسم السواحب", divisionManager: "علي الفيتوري مصباح" },

        ],
      },
    },
    {
      id: "9",
      name: "إدارة الشؤون الإدارية",
      image: "departments/إدارة-الشــؤون-الادارية.webp",
      buttonText: "تعرف علي إدارة الشؤون الإدارية من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الشؤون الإدارية",
        managerPhoto: "",
        managerName: "عصام البوراوي",
        mainPhoto: "departments/إدارة-الشــؤون-الادارية.webp",
        divisions: [
          { divisionName: "قسم الخدمات", divisionManager: "محمد محمد الراجحي" },
          { divisionName: "قسم المحفوظات", divisionManager: "فاطمة سعيد الرحيبي" },
          { divisionName: "قسم شؤون العاملين", divisionManager: "منصور الشيباني ابوعيسى" },
          { divisionName: "قسم اللاسلكي", divisionManager: "عبدالسلام صالح موسى" },
        ],
      },
    },{
      id: "10",
      name: "إدارة الشؤون المالية",
      image: "departments/إدارة-الشــؤون-المالية.webp",
      buttonText: "تعرف علي إدارة الشؤون المالية من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الشؤون المالية",
        managerPhoto: "",
        managerName: "",
        mainPhoto: "departments/إدارة-الشــؤون-المالية.webp",
        divisions: [{ divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },
          { divisionName: "", divisionManager: "" },

        ],
      },
    },
    {
      id: "11",
      name: "إدارة الطوارئ",
      image: "departments/إدارة-الطوارئ.webp",
      buttonText: "تعرف علي إدارة الطوارئ من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الطوارئ",
        managerPhoto: "",
        managerName: "جمال عمر مسعود",
        mainPhoto: "departments/إدارة-الطوارئ.webp",
        divisions: [
          { divisionName: "قسم صيانة التجهيزات", divisionManager: "عبدالسلام موسى ابوسليوه" },
          { divisionName: "قسم طوارئ الإمطار", divisionManager: "عبدالخالق محمد منصور" },
          { divisionName: "قسم طوارئ الكوارث", divisionManager: "فؤاد بشير الناجم" },
          { divisionName: "قسم طوارئ النظافة", divisionManager: "محمد ونيس صالح" },
          { divisionName: "قسم طوارئ الطرق", divisionManager: "أنور ميلاد ابوسنينة" },
        ],
      },
    },
    {
      id: "12",
      name: "إدارة المكبات",
      image: "departments/إدارة-المكبات.webp",
      buttonText: "تعرف علي إدارة المكبات من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة المكبات",
        managerPhoto: "",
        managerName: "",
        mainPhoto: "departments/إدارة-المكبات.webp",
        divisions: [
          // { divisionName: "", divisionManager: "" },
        ],
      },
    },
    {
      id: "13",
      name: "إدارة النظافة",
      image: "departments/إدارة-النظافة.webp",
      buttonText: "تعرف علي إدارة النظافة من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة النظافة",
        managerPhoto: "",
        managerName: "سلمان عمر الشرع",
        mainPhoto: "departments/النظافة.webp",
        divisions: [
          { divisionName: "قسم العقود ومتابعة الشركات المساندة", divisionManager: "حسين عمر الجمل" },
          { divisionName: "قسم الكنس وإزالة الأتربة", divisionManager: "عبدالحميد بشير الككلي" },
          { divisionName: "قسم العمل الليلي", divisionManager: "منير الهادي صالح" },
          { divisionName: "قسم متابعة صناديق القمامة", divisionManager: "خالد سعد اعشيبة" },

        ],
      },
    },
    {
      id: "14",
      name: "إدارة الدعم والإسناد",
      image: "departments/إدارة-الدعم-والاسناد.webp",
      buttonText: "تعرف علي إدارة الدعم والإسناد من هنا",
      state: false,
      departmentDetails: {
        title: "إدارة الدعم والإسناد",
        managerPhoto: "",
        managerName: "",
        mainPhoto: "departments/إدارة-الدعم-والاسناد.webp",
        divisions: [
          // { divisionName: "", divisionManager: "" },
        ],
      },
    },
    
  ];