export const addsBarData = [
    {src:'images/adds/1.jpg', alt:'إعلانات'},
    {src:'images/adds/2.jpg', alt:'إعلانات'},
    {src:'images/adds/3.jpg', alt:'إعلانات'},
    {src:'images/adds/4.jpg', alt:'إعلانات'},
    {src:'images/adds/5.jpg', alt:'إعلانات'},
    {src:'images/adds/6.jpg', alt:'إعلانات'},
    {src:'images/adds/7.jpg', alt:'إعلانات'},
    {src:'images/adds/8.jpg', alt:'إعلانات'},
    {src:'images/adds/9.jpg', alt:'إعلانات'},
    {src:'images/adds/10.jpg', alt:'إعلانات'},
    {src:'images/adds/11.jpg', alt:'إعلانات'},
    {src:'images/adds/12.jpg', alt:'إعلانات'},
    {src:'images/adds/13.jpg', alt:'إعلانات'},
    {src:'images/adds/14.jpg', alt:'إعلانات'},
    {src:'images/adds/15.jpg', alt:'إعلانات'},
    {src:'images/adds/16.jpg', alt:'إعلانات'},
    {src:'images/adds/17.jpg', alt:'إعلانات'},
    {src:'images/adds/18.jpg', alt:'إعلانات'},
    {src:'images/adds/19.jpg', alt:'إعلانات'},
    {src:'images/adds/20.jpg', alt:'إعلانات'},
    {src:'images/adds/21.jpg', alt:'إعلانات'},
    {src:'images/adds/22.jpg', alt:'إعلانات'},
    {src:'images/adds/23.jpg', alt:'إعلانات'},
    {src:'images/adds/24.jpg', alt:'إعلانات'},
    {src:'images/adds/25.jpg', alt:'إعلانات'},
    {src:'images/adds/26.jpg', alt:'إعلانات'},
    {src:'images/adds/27.jpg', alt:'إعلانات'},
    {src:'images/adds/28.jpg', alt:'إعلانات'},
    {src:'images/adds/29.jpg', alt:'إعلانات'},
    {src:'images/adds/30.jpg', alt:'إعلانات'},
]