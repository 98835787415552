import React from "react";
import { useLocation } from "react-router-dom";

function Header() {
  const { pathname } = useLocation();

  return (
    <div className='header'>
      <div className='container row'>
        <div className='col-6'>
          <div className='my-4 '>
            <a href='/home'>
              <img
                height='90%'
                width='90%'
                className='mt-2'
                src={"images/Logo1.webp"}
                alt='logo'
              />
            </a>
          </div>
        </div>
        <div className='col-6 d-none d-md-inline text-end '>
          <div className='mt-5'>
            <p className='d-inline ms-1'> تابعـــونا على </p>
            <span className='ms-2'>
              <a href='https://www.facebook.com/ServicesCompany/' target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-facebook-f my-soial-icon"></i>
              </a>
            </span>
            <span className='ms-2'>
              <a href='https://twitter.com/psctly?s=11&t=UUt70eUd6wnrFtUkz0BUew' target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-twitter my-soial-icon"></i>
              </a>
            </span>
          </div>
        </div>

        <div className='col'>
          <nav className='navbar navbar-expand-md'>
            <button
              className='navbar-toggler ms-auto mt-4  btn-outline-light '
              type='button'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasNavbar'
              aria-controls='offcanvasNavbar'
            >
              <i className='fa-solid fa-bars fs-4'></i>
            </button>
            <div
              className='offcanvas offcanvas-end'
              tabIndex='-1'
              id='offcanvasNavbar'
              aria-labelledby='offcanvasNavbarLabel'
            >
              <div
                className='offcanvas-header  '
                style={{ backgroundColor: "#1C2C6A" }}
              >
                <div className='d-md-none'>
                  <a href='/home'>
                    <img
                      height='90%'
                      width='90%'
                      className='mt-2'
                      src={"images/Logo1.webp"}
                      alt='logo'
                    />
                  </a>
                </div>

                <button
                  type='button'
                  className='btn-close text-reset me-2 my-btn-close'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                ></button>
              </div>
              <div
                className='offcanvas-body'
                style={{ backgroundColor: "#1C2C6A" }}
              >
                <ul className='navbar-nav text-center m-auto mt-5 mt-md-1'>
                  <li className='nav-item '>
                    <a
                      href='/home'
                      className={`nav-link  ${
                        pathname === "/home"
                          ? "btn btn-light bg-light  a-link-active"
                          : "text-light"
                      }`}
                    >
                      الرئيسية
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='/news'
                      className={`nav-link  ${
                        pathname === "/news"
                          ? "btn btn-light bg-light  a-link-active"
                          : "text-light"
                      }`}
                    >
                      أخر الأخبار
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='/decisions'
                      className={`nav-link  ${
                        pathname === "/decisions"
                          ? "btn btn-light bg-light  a-link-active"
                          : "text-light"
                      }`}
                    >
                      القرارات
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='/departments'
                      className={`nav-link  ${
                        pathname === "/departments"
                          ? "btn btn-light bg-light  a-link-active"
                          : "text-light"
                      }`}
                    >
                      الإدارات
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='/issue-report'
                      className={`nav-link  ${
                        pathname === "/issue-report"
                          ? "btn btn-light bg-light  a-link-active"
                          : "text-light"
                      }`}
                    >
                      البلاغات
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='/map'
                      className={`nav-link  ${
                        pathname === "/map"
                          ? "btn btn-light bg-light  a-link-active"
                          : "text-light"
                      }`}
                    >
                      الخريطة
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
