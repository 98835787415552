import React, { useState } from "react";
import Vieew from "./view";
import { departments } from "./data";
import "./Departments.css";
import SeeMore from "../../components/SeeMore/SeeMore";


//here data of each department
// we need to set departmentDetails data, it's a data you want to display it into department details page


function Departments() {
  const [dataShow, setDataShow] = useState(departments[0]);

  return (
    <>
      <div className='container mt-5'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item fw-bold'>
              <a href='/home'>الرئيسة</a>
            </li>
            <li className='breadcrumb-item fw-bold text-muted' aria-current='page'>
              الإدارات
            </li>
          </ol>
        </nav>
      </div>

      <div className='d-flex justify-content-center mt-5'>
        <div className='w-75'>
          <p className="h4">تنقسم شركة الخدمات للعديد من الإدارات تعرف عليها هنا</p>
          <div className='border-top border-1 border-dark'></div>
        </div>
      </div>



      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='text-center'>
              <Vieew data={dataShow} />
            </div>
          </div>

          <div className='col-xs-12 col-md-5 '>
            <div>
              <h5>الإدارات</h5>
            </div>
            {/* large schreen */}
            <div className='d-none d-md-block overflow-auto w-100 h-75'>
              <ul className='d-none d-md-block list-group list-group-flush my-list-group'>
                {departments.map((item,index)=>{
                  return(
                    <li className='list-group-item' key={index}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setDataShow(departments[index]);
                      }}
                      className={
                        dataShow === departments[index]
                          ? `btn my-bg-light btn-active`
                          : `btn my-bg-light`
                      }
                    >
                      {departments[index].name} 
                    </button>
                  </li>
                  )
                })}
              
             
              </ul>
            </div>
            {/* small schreen */}
            <div className='d-block d-md-none overflow-auto w-100 h-75'>
              <ul className='d-block d-md-none navbar-nav my-list-group'>
              {departments.map((item,index)=>{
                  return(
                    <li className='list-group-item' key={index}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setDataShow(departments[index]);
                      }}
                      className={
                        dataShow === departments[index]
                          ? `btn my-bg-light btn-active`
                          : `btn my-bg-light`
                      }
                    >
                      {departments[index].name} 
                    </button>
                  </li>
                  )
                })}
              
              </ul>
            </div>
          </div>
        </div>
      </div>


      <SeeMore/>
    </>
  );
}

export default Departments;
