import React from 'react';
import { Navigate,useRoutes } from 'react-router-dom';

// screens
import Home from './Screens/Home/Home'
import News from './Screens/News/News'
import Decisions from './Screens/Decisions/Decisions'
import NotFound from './Screens/NotFound/NotFound'
import MapScreen from './Screens/MapScreen/MapScreen';
import IssueReport from './Screens/IssueReport/IssueReport';

// import Dashboard from './Layout/Dashboard.js';
import LandingPage from './Layout/LandingPage';
import Departments from './Screens/Departments/Departments';
import Department from './Screens/Departments/Department';

// Admin Portal screens
// import PortalNews from './Screens/Admin/Screens/PortalNews';
// import PortalContainers from './Screens/Admin/Screens/PortalContainers';
// import PortalReports from './Screens/Admin/Screens/PortalReports';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LandingPage />,
      children: [
      {path:'/',element:<Navigate to='/home'/>},
      {path: '/home',element: <Home/>},
      {path: '/news',element: <News/>},
      {path: '/decisions',element: <Decisions/>},
      {path: '/departments',element: <Departments/>},
      {path: '/department',element: <Department/>},
      {path: '/issue-report',element: <IssueReport/>},
      {path: '/map',element: <MapScreen/>},
      {path: '*',element: <Navigate to="/404" replace />},

      ],
    },
    // {
    //   path: '/admin',
    //   element:<Dashboard />,
    //   children: [
    //     {path:'/admin',element:<Navigate to='/admin/reports'/>},
    //     {path: '/admin/reports',element: <PortalReports/>},
    //     {path: '/admin/news',element: <PortalNews/>},
    //     {path: '/admin/containers',element: <PortalContainers/>},
        
    //   ],
    // },
    { path: '/404', element:<NotFound/>  },
  ]);
}
