import React, { useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import "./IssueReport.css";
import SeeMore from "../../components/SeeMore/SeeMore";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner } from "reactstrap";
import Swal from 'sweetalert2'

function IssueReport() {
  const [loading, setLoading] = useState(false);

  // const [alert, setAlert] = useState(false);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
  ];
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("يجب ادخال إيميل")
      .matches(/^[a-zA-Z @.-_]+$/, "الايميل غير صحيح")
      .email(),
    issue: yup.string().required("يجب ادراج تفاصيل عن البلاغ"),
    image: yup
      .mixed()
      .required("يجب رفع صورة")
      .test(
        "fileFormat",
        "ما قمت برفعه ليس صورة",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });
  let formData = new FormData();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    formData.append("email", data.email);
    formData.append("content", data.issue);
    formData.append("image", data.image);
    setLoading(true);
    await axios
      .post(`https://ps-node-prod.psct.gov.ly/reports`, formData, config)
      .then(() => {
        reset({
          email: "",
          issue: "",
          image: "",
        });
        setLoading(false);
        Swal.fire(
          'Good job!',
          'تمت العملية بنجاح',
          'success'
        )
      })
      .catch((e) => {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'فشلت العملية، الرجاء المحاولة مرة أخرى',
        })
      });
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    setValue("image", event.target.files[0]);
  };

  return (
    <div className="container">
      <div style={{ marginTop: "6rem" }}>
        <div className='d-flex  mb-5'>
          <div style={{ width: "95%" }}>
            <p className='h4'>شركة الخدمات العامة - تقديم بلاغ رسمي</p>
            <div className='border-top border-1 border-dark'></div>
          </div>
        </div>

        <div className='mt-5 mb-5'>
          {!loading ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row ms-5 mb-5'>
                <div className='col-xs-6 col-md-6'>
                  <label className='form-label'>البريد الإلكتروني</label>
                  <input
                    type='text'
                    {...register("email")}
                    className='form-control w-50'
                    placeholder='name@example.com'
                  />
                  {errors.email && (
                    <p className='text-danger fw-bold'>
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>

              <div className='row ms-5 mb-3'>
                <div className='col '>
                  <label className='form-label'>البلاغ</label>
                  <textarea
                    className='form-control'
                    {...register("issue")}
                    style={{ width: "80%" }}
                    rows='7'
                  ></textarea>
                  {errors.issue && (
                    <p className='text-danger fw-bold'>
                      {errors.issue.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='row ms-5'>
                <div className='col-xs-12  col-md-5  mt-2 d-md-flex justify-content-end'>
                  {errors.file && (
                    <p className='me-3 mt-1 text-danger fw-bold'>
                      {errors.file.message}
                    </p>
                  )}
                  <div
                    onClick={handleClick}
                    className='my-btn fw-bold  my-hover '
                  >
                    رفع صورة{" "}
                    <Icon
                      className='ms-2 my-icon my-hover'
                      icon='fa-solid:cloud-upload-alt'
                      width='30'
                      height='30'
                    />
                  </div>
                  <input
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    type='file'
                    style={{ display: "none" }}
                  ></input>
                </div>
                <div className='col-xs-12 col-md-7 mt-2'>
                  <button
                    className='my-btn2 btn-primary-outline fw-bold'
                    type='submit'
                    style={{ borderRadius: "8px" }}
                  >
                    تقديم البلاغ
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="container text-center">
              <Spinner color='primary' />
            </div>
          )}
        </div>


      </div>

      <SeeMore />
    </div>
  );
}

export default IssueReport;
