import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import {Spinner} from 'reactstrap'

function SeeMore() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await axios.get(
        "https://ps-node-prod.psct.gov.ly/news?page=1"
      );

      setNews(res.data.news.rows);
      setLoading(false);
    };
    fetch();
  }, []);

  return (
      <div className='container'>
        <div className='row justify-content-star'>
          <div className='col-12 ms-1'>
            <h5 className='my-text-primary'> اطلع على المزيد</h5>
          </div>
          <div className='col-2'></div>
        </div>

        <div className='row justify-content-around mt-4'>
          {loading ?
                      <div className='text-center'>
                        <Spinner color="primary"/>
                    </div>
          :
          
          news.slice(0,3).map((item) => {
            return (
              <div className='col-xs-12 col-md-6 col-lg-3 mt-4' key={item.newsId}>
                <div className='card'>
                  <img
                    className='card-img-top'
                    src={`https://ps-node-prod.psct.gov.ly//${item.image}`}
                    alt=''
                  />
                  <div className='card-body'>
                    <h6 className='card-title'>
                    {item.title}
                    </h6>
                    <p className='card-text text-muted'>
                      <small className=''>
                        <i className='fa-solid fa-timer'></i>
                        <Icon icon='bx:time' width='15' height='15' />
                        {item.createdAt.split("T")[0]}
                      </small>
                      <small className='ms-3'>
                      {item.department === null || "" ? (
                            ""
                          ) : (
                            <>
                              <Icon
                                icon='mdi:office-building-outline'
                                width='10'
                                height='10'
                              />{" "}
                              {item.department}
                            </>
                          )}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    
  );
}

export default SeeMore;

