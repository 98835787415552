import React from 'react'
import {useNavigate} from 'react-router-dom'



function TrashContainerSearch() {
  const navigate = useNavigate()
  return (
    <div className="card  mx-auto container map-container">
    <div className="row ">
      <div className="col col-xs-12 col-md-5 mt-5 text-center">
      <div className="card-body">
          <h5 className="card-title my-text-primary">خريطة حاويات القمامة</h5>
          <p className="card-text text-start text-dark">
            <span> أعثر على أقرب حاوية قمامة لك لتساهم

            في الحفاظ على نظافة بلادنا الحبيبة </span>
          </p>
          
          <button className="btn card-btn mx-auto mt-1 mt-xs-1 mt-sm-2 mt-md-2 mt-lg-2 mt-xl-4" onClick={(e)=>{
            navigate('/map')
          }}>افتح</button>
      </div>
      </div>
      <div className="d-none d-md-block col-md-7">
      <img className="card-img-top map-img h-100 w-100" src={"images/MacBook-Pro-17.webp"} alt='map' />
      </div>
    </div>
      
  
  </div>
  )
}

export default TrashContainerSearch