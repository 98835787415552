import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ReactPaginate from "react-paginate";
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import {Spinner} from 'reactstrap'



import axios from "axios";
function News() {
  const schema = yup.object().shape({
    searchTitle: yup.string().trim().matches(/^[\u0621-\u064A0-9 -_:.,،]+$/,'يجب أن يحتوي على حروف فقط').required('يجب ادخال نص'),
  })
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });

  let formData = new FormData();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await axios.get(
        "https://ps-node-prod.psct.gov.ly/news?page=1"
      );
      setPageCount(Math.ceil(res.data.news.count / 6));
      
      setNews(res.data.news.rows);
      setLoading(false);
    };
    fetch();
  }, []);

  const fetchPage = async (currentPage) => {
    setLoading(true);
    const res = await axios.get(
      `https://ps-node-prod.psct.gov.ly/news?page=${currentPage}`
    );
    return res.data;
  };

  const searchFilter = async (data, searchCurrentPage = 1) => {
    //formdata object
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    formData.append("keyword", data); //append the values with key, value pair

    setLoading(true);
    const res = await axios.post(
      `https://ps-node-prod.psct.gov.ly/news/find?page=${searchCurrentPage}`,
      formData,
      config
    );
    return res.data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const res = await fetchPage(currentPage);
    setNews(res.news.rows);
    setLoading(false);
  };

  const onSubmit =async (data)=>{
      const res = await searchFilter(data.searchTitle);
      setPageCount(Math.ceil(res.news.count / 6));
      setNews(res.news.rows);
      setLoading(false);
  }

  return (
    <>
      <div className='card m-auto container map-container my-5'>
        <div className='row'>
          <div className='col'>
            <div className='card-title text-start mt-3 ms-2'>
              <h4>قم بالبحث في الأخبار</h4>
            </div>
            <div className='card-subtitle text-muted text-start mt-2 ms-2'>
              <small>يمكنك البحث عن الأخبار باستخدام الكلمات المفتاحية</small>
              <br />
              <small>مثل (أعمال نظافة, إدارة الحدائق, مكتب أبوسليم)</small>
            </div>
            <div className='card-body'>
              <form className='form-group' onSubmit={handleSubmit(onSubmit)}>
                <input
                  type='text'
                  className='form-control w-75 m-auto'
                  placeholder='ابحث عن...'
                  {...register('searchTitle')}
                />
                {errors.searchTitle && <p className="text-danger fw-bold">{errors.searchTitle.message}</p>}
                <button className='btn btn-block' type='submit'>
                  بحث <Icon icon='bx:search-alt-2' width='20' height='20' />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='container news-container'>
        <div className='d-flex justify-content-center mb-5'>
          <div style={{ width: "95%" }}>
            <p className="h4">شركة الخدمات العامة - الأخبار</p>
            <div className='border-top border-1 border-dark'></div>
          </div>
        </div>
        <div className='row justify-content-around'>
          {loading === false ? (
            news.map((item) => {
              return (
                <div className='col-xs-12 col-md-6 mt-4 ' key={item.newsId}>
                  <div className='card '>
                    <img
                      className='card-img-top'
                      src={`https://ps-node-prod.psct.gov.ly/${item.image}`}
                      width='100rem'
                      height='260rem'
                      alt=''
                    />
                    <div className='card-body '>
                      <h6 className='card-title'>{item.title}</h6>
                      <p className='card-text text-muted'>
                        <small className=''>
                          <i className='fa-solid fa-timer'></i>
                          <Icon
                            icon='healthicons:i-schedule-school-date-time-outline'
                            width='20'
                            height='20'
                          />{" "}
                          {item.createdAt.split("T")[0]}
                        </small>

                        <small className='ms-3'>
                          {item.department === null || "" ? (
                            ""
                          ) : (
                            <>
                              <Icon
                                icon='mdi:office-building-outline'
                                width='10'
                                height='10'
                              />{" "}
                              {item.department}
                            </>
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='text-center my-text-primary'>
                <Spinner color="primary">
                  
                </Spinner>
            </div>
          )}
        </div>
      </div>

      {pageCount > 1 ? (
        <ReactPaginate
          previousLabel='<<'
          nextLabel='>>'
          breakLabel='...'
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center my-5"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default News;
