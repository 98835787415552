import React from 'react'


function CompanyStructure() {
  return (
    <>
    <div className='container '>
        <div className='row'>
            <div className='col'>
            <img className='h-100 w-100' src='images/poter-pc.webp' alt='structure'/>
            </div>
        </div>
    </div>
    </>
  )
}

export default CompanyStructure