import React , {useEffect,useState} from 'react'
import { Icon } from '@iconify/react';
import axios from 'axios';




function NewsBoard() {
  const [news,setNews] = useState([])



  const fetch =()=>{
    axios.get('https://ps-node-prod.psct.gov.ly/news/').then((res)=>{
      setNews(res.data.news.rows)
    })
  }

  useEffect(() => {

    fetch()

  }, [])


    
  return (
    <div className="container row mt-5">

<div className="row justify-content-between align-items-center">
  <div className="col-6 col-md-6  ">
    <h6 className="my-text-primary"> أبرز الأخبار</h6>
  </div>
  
  <div className="col-6 col-md-6 text-end ">
    <a href='/news' className='nav-link'>
    <h6 className="my-text-primary">عرض الكل <img className='img-icon' src={'images/Vector.webp'} alt='vector'/> </h6>
    </a>
  </div>
</div>


<div className="row justify-content-around">
{ news.slice(0,4).map((item) =>{
  return (
<div className="col-xs-12 col-md-6  mt-4" key={item.newsId}>
  <div className="card">
    <img className="card-img-top" src={`https://ps-node-prod.psct.gov.ly/${item.image}`}  alt=""/>
    <div className="card-body ">
        <h6 className="card-title">{item.title}</h6>
        <div className="card-text text-muted">
          <small className="">
      <i className="fa-solid fa-timer"></i>
      <Icon icon="healthicons:i-schedule-school-date-time-outline" width="20" height="20" />  {
        item.createdAt.split('T')[0]
      }   
      </small>

      <small className="ms-3">
        {item.department === null || '' ? '' :
        <>
        <Icon icon="mdi:office-building-outline" width="10" height="10" />  { item.department}
        </>
        }
          </small>

        <div className='text-end'>
          {item.content ? (<a href='/new-details'>إقراء المزيد</a>):''}
        </div>

        </div>

    </div>
</div>
  </div>
  )
})}

  

</div>
</div>
  )
}

export default NewsBoard