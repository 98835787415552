import React from "react";
import "./Home.css";
import TrashContainerSearch from "./TrashContainerSearch";
import AdsBar from "./AdsBar";
import NewsBoard from "./NewsBoard";
import DecesionsBoard from "./DecesionsBoard";
import CompanyStructure from "./CompanyStructure";

// import DepartmentsBoard from "./DepartmentsBoard";

function Home() {
  return (
    <>
      <TrashContainerSearch />
      <AdsBar />
      <CompanyStructure/>
      <NewsBoard />
      <div className='background mt-5 '>
        <div className='layer'>
          <div className='row container'>
            <div className="col">
            <p className='text-light h2 mt-5 ms-5 report-panner'>
              سعيا منا لتسهيل عملية التواصل بيننا وبين المواطنين يمكنكم الان
              التواصل معنا وتقديم البلاغات مباشرة من موقع شركة الخدمات العامة
              الإلكتروني الجديد
            </p>

            </div>
         
              <div className="col-xs-6 text-end">
                <a href="/issue-report">

                <img className="report-btn-img" src='images/report.webp' alt="report button"/>
                </a>
              </div>


          </div>
        </div>
      </div>
      <div className='row justify-content-between align-items-center mt-5 container'>
<div className='col-6 col-md-6 '>
  <p className="h6"> قوانين وقرارات شركة الخدمات العامة</p>
</div>

<div className='col-6 col-md-6 text-end'>
  <a href='/decisions' className='nav-link'>
    <p className='h6 my-text-primary'>
      عرض الكل
      <img
        className='img-icon'
        src={"images/Vector.webp"}
        alt='vector'
      />
    </p>
  </a>
</div>
</div>
      <DecesionsBoard />
      {/* <DepartmentsBoard /> */}
    </>
  );
}

export default Home;
